import React from "react";
import { LeaguePoint } from "../LeaguePoint";
import { getRank } from "../../Common/utils/league";
import { getTranslations } from "../../Common/utils";

const HeadToHeadPoints = ({
  user,
  opponent,
  userPoints = null,
  opponentPoints = null,
  userBooster = {},
  opponentBooster = {},
  isUserWin = false,
  isOpponentWin = false,
  handleOpponentClick = () => {},
  handleUserClick = () => {},
}) => {
  return (
    <div className="si-league__point-grid">
      <LeaguePoint
        LeaguePointLbl={
          <>
            <span className="si-myTeamHeader__serial-number">
              T{user?.teamNo}
            </span>
            <span className="si-myTeamHeader__serial-lbl">
              {user?.teamName}
            </span>
          </>
        }
        LeaguePointNum={getRank(userPoints ? userPoints : user?.points)}
        booster={{
          ...userBooster,
          hideBoosterText: true,
        }}
        isWon={isUserWin}
        handleClick={() => null}
      />
      <div className="si-league__point-vs">
        <span>
          {getTranslations("league_card_head_to_head_vs_label", "vs")}
        </span>
      </div>
      <LeaguePoint
        LeaguePointLbl={
          <>
            <span className="si-myTeamHeader__serial-number">
              {opponent ? `T${opponent?.teamNo}` : ""}
            </span>
            <span className="si-myTeamHeader__serial-lbl">
              {opponent
                ? opponent?.teamName
                : getTranslations(
                    "league_hth_opponent_yet_to_join",
                    "Yet to join"
                  )}
            </span>
          </>
        }
        LeaguePointNum={getRank(
          opponentPoints ? opponentPoints : opponent?.points
        )}
        booster={{ ...opponentBooster, hideBoosterText: true }}
        isWon={isOpponentWin}
        handleClick={() => null}
      />
    </div>
  );
};

export { HeadToHeadPoints };
