import { useMemo } from "react";
import { currentFixture } from "../utils/fixture";
import { useSelector } from "react-redux";

const useSelectedRaceFixture = (selectedRaceOption) => {
  const fixtureState = useSelector((state) => state.fixture);

  const selectedRaceFixture = useMemo(() => {
    const selectedRaceGamedayId = selectedRaceOption?.id;
    if (selectedRaceGamedayId === 0) {
      return currentFixture(fixtureState);
    } else {
      return fixtureState?.list?.find(
        (fixture) => fixture?.GamedayId === selectedRaceGamedayId
      );
    }
  }, [selectedRaceOption, fixtureState]);

  return selectedRaceFixture;
};

export default useSelectedRaceFixture;
