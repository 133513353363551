import React, { useEffect } from "react";
import { MatchHeader } from "../../MatchHeader";
import { currentFixture } from "../../../Common/utils/fixture";
import { HeadToHeadPoints } from "../../HeadToHead";
import { MatchRecord } from "../../MatchRecord";
import { useDispatch, useSelector } from "react-redux";
import { getTranslations } from "../../../Common/utils";
import { league } from "../../../redux/actions";
import {
  getH2HCurrentUserTeam,
  getH2HOpponentUserTeam,
  h2hCurrentUser,
  h2hOpponentUser,
} from "../../../Common/utils/league";
import useSelectedRaceFixture from "../../../Common/hooks/useSelectedRaceFixture";
import { ErrorMessage } from "../../Error/message";

const H2HBattleModeCurrentFixture = ({
  selectedRaceOption,
  isYetToJoin = false,
}) => {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);
  const userState = useSelector((state) => state.user);
  const leagueState = useSelector((state) => state.league);
  const selectedRaceFixture = useSelectedRaceFixture(selectedRaceOption);

  useEffect(() => {
    try {
      const payload = {
        guid: userState?.data?.GUID,
        leagueId: leagueState.selectedLeague.leagueId,
        teamNo: 1,
        gamedayid: selectedRaceOption?.id,
      };

      dispatch(league.getH2HSingleRaceResult(payload));
    } catch (error) {}
  }, [
    dispatch,
    userState?.data?.GUID,
    leagueState?.selectedLeague?.leagueId,
    selectedRaceOption,
  ]);

  return (
    <div className="si-battle__head">
      <div className="si-battle__matchHeader">
        <MatchHeader
          MatchHeaderConfig={{
            MatchHeaderRHS: false,
            MatchHeaderLHS: false,
            MatchFixtureInfo: false,
            MatchHeaderExtra: true,
          }}
          fixture={selectedRaceFixture}
          tagName="h2"
        />
        <MatchHeader
          MatchHeaderConfig={{
            MatchHeaderRHS: false,
            MatchFixtureInfo: false,
          }}
          fixture={selectedRaceFixture}
          tagName="h2"
        />
      </div>

      <HeadToHeadPoints
        user={h2hCurrentUser(leagueState?.h2hSingleRaceResultData)}
        opponent={h2hOpponentUser(leagueState?.h2hSingleRaceResultData)}
        isUserWin={
          h2hCurrentUser(leagueState?.h2hSingleRaceResultData)?.battleResult ===
          "W"
        }
        isOpponentWin={
          h2hOpponentUser(leagueState?.h2hSingleRaceResultData)
            ?.battleResult === "W"
        }
        userBooster={
          isYetToJoin
            ? null
            : gameplayState?.selectedBooster?.[
                h2hCurrentUser(leagueState?.h2hSingleRaceResultData)?.teamNo - 1
              ]
        }
        opponentBooster={
          gameplayState?.h2hOppTeam?.selectedOpponentBooster?.[0]
        }
        userPoints={
          isYetToJoin
            ? null
            : getH2HCurrentUserTeam(
                gameplayState,
                h2hCurrentUser(leagueState?.h2hSingleRaceResultData),
                selectedRaceOption
              )?.gdpoints ?? "-"
        }
        opponentPoints={getH2HOpponentUserTeam(gameplayState)?.gdpoints ?? "-"}
      />
      {h2hCurrentUser(leagueState?.h2hSingleRaceResultData)?.battleResult ===
        "D" && (
        <ErrorMessage
          customCls={"si-errMsg--default"}
          text={getTranslations("league_hth_season_so_far_draw", "Draw")}
          iconClassName={false}
        />
      )}
      {selectedRaceOption?.id === 0 && (
        <MatchRecord
          heading={getTranslations(
            "league_hth_previous_result",
            "Previous result:"
          )}
          dataMatchRecord={h2hCurrentUser(
            leagueState?.h2hSingleRaceResultData
          )?.lastFiveResult?.map((x) => ({ type: x }))}
        />
      )}
    </div>
  );
};

export default H2HBattleModeCurrentFixture;
